@import "../includes/variables";

.MuiInputBase-input {
  height: 2rem;
}

table.table {
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2);
}

table.table th {
  color: #000;
  background-color: #ffffff !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  border-top: solid 1px #d9dfea !important;
  border-bottom: solid 1px #d9dfea !important;
}

table.table th.align-right {
  text-align: right;
}

table.table img.profile {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: solid 1px #d9dfea;
}

table.table td {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  background-color: #ffffff !important;
  color: #000;
  font-size: 16px !important;
  vertical-align: middle;
}

table.table td .rank-order td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

table.table td.align-right {
  text-align: right;
}

table.table td strong {
  font-weight: 600;
}

table.table-binary td {
  font-size: 16px !important;
}

table.table-binary td a {
  color: #003271;
}

table.table-binary td a:hover {
  text-decoration: underline !important;
}

table.table-binary td:nth-child(2) {
  text-align: right;
}

/*
* TYPOGRAPHY: FONT SIZES AND LINE HEIGHTS
 */
h1 {
  font-size: 32px;
  line-height: 48px;
  color: #003271;
}

h2 {
  font-size: 24px;
  line-height: 36px;
}

h3 {
  font-size: 19px;
  line-height: 28.5px;
}

p,
a,
strong {
  font-size: 16px;
  line-height: 24px;
}

table td {
  font-size: 13px;
}

small {
  font-size: 8px;
  line-height: 12px;
}

h1,
h2,
h3,
strong {
  font-weight: 600;
  margin: 0 !important;
}

p,
small {
  margin: 0 !important;
}

p.label {
  color: #aaaaaa !important;
}

.primary-navbar {
  background: #ffffff;
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2) !important;
}

.primary-navbar a {
  text-decoration: none !important;
}

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}


.switch-language-toolbar .btn-primary {
  background-color: #d9dfea !important;
  border: solid 1px #d9dfea !important;
  color: #7b8d8d !important;
  margin-right: 10px;
  cursor: pointer;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.switch-language-toolbar .btn-primary span {
  margin-left: 10px !important;
  opacity: 0;
}

.switch-language-toolbar .btn-primary:hover span {
  opacity: 1;
}

.switch-language-toolbar .btn-primary.btn-add {
  background-color: #f97b6b !important;
  color: #ffffff !important;
  border: none !important;
  font-weight: 600;
}

.switch-language-toolbar .btn-primary.active {
  background-color: #003271 !important;
  border: solid 1px #003271 !important;
  color: #ffffff !important;
}

button:disabled {
  background-color: #cccccc !important;
  color: #878787 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

input,
select {
  background-color: $secondary-color !important;
  padding: 0 16px !important;
  /* border: solid 1px #a1a9c3 !important; */
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  border-radius: 3px !important;
  /* height: 46px !important; */
  color: #003271 !important;
}

input:focus, select:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
}

.input-search input {
  border-right: none !important;
  -webkit-border-bottom-right-radius: 0 !important;
  -moz-border-bottom-right-radius: 0 !important;
  -ms-border-bottom-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  -webkit-border-top-right-radius: 0 !important;
  -moz-border-top-right-radius: 0 !important;
  -ms-border-top-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.input-search .input-group-append span {
  background-color: #f1f2f6;
  border-right: solid 1px #a1a9c3 !important;
  border-top: solid 1px #a1a9c3 !important;
  border-bottom: solid 1px #a1a9c3 !important;
}

/*
* CUSTOM STYLING ENDS HERE
 */
.accordion .card {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
  overflow: visible !important;
  border-left: none !important;
}

.accordion .card .card-header {
  padding: 0 !important;
  background-color: #fff !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  border-radius: 0 !important;
}

.accordion .card .card-header button {
  padding: 16px !important;
  color: #000 !important;
  font-size: 13px !important;
}

.accordion-header {
  border-bottom: #eff5fe;
  height: 80px !important;
}



.modal-title {font-weight: 600;}

.modal label {
  font-weight: 600;  color: #003271 !important;
}

.modal-header, .modal-footer {
  border: none !important;
}

.modal-600 {
  width: 600px !important;
}

.panel-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
}

.carousel-inner {
  background-color: blue;
  height: 100%;
}

.carousel-inner .carousel-item {
  background-color: red;
  height: 100%;
}

.carousel-inner .carousel-item .preview {
  height: 100%;
}

.hotel-card {
  background-color: #ffffff;
  padding: 16px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2) !important;
}

.hotel-card h2 {
  color: #003271;
}

.hotel-card p.address {
  color: #7b8d8d;
}

.hotel-card:hover {
  -webkit-transition: ease-out all 0.5s !important;
  -moz-transition: ease-out all 0.5s !important;
  transition: ease-out all 0.5s !important;
  box-shadow: 20px 40px 60px 0 rgba(0, 0, 0, 0.1) !important;
}

.drag-card-article {
  background-color: #f6f6f7 !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  border: solid 1px #d9dfea;
  padding: 12px;
}

.drag-card-article .media-type img,
.drag-card-article .close-btn img {
  height: 16px;
}

.drag-card-article .close-btn {
  float: right;
}

.drag-card-article p {
  color: #003271;
}

.drag-card .rank-order {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drawer-cta {
  width: 100%;
  position: absolute;
  bottom: 0 !important;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2);
}

.cta-buttons {
  text-align: right;
  margin-right: 32px;
}

.drawer-head h2 {
  color: #003271;
}

.drawer-content {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  overflow-x: hidden;
  height: 100%;
}

.add-form-content {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

.p-t-200 {
  padding-top: 200px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

.p-b-200 {
  padding-bottom: 200px !important;
}

/**/
.p-l-0 {
  padding-left: 0 !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

.p-l-200 {
  padding-left: 200px !important;
}

/**/
.p-r-0 {
  padding-right: 0 !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

.p-r-200 {
  padding-right: 200px !important;
}

/*margins*/
.m-t-0 {
  margin-top: 0 !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

.m-t-200 {
  margin-top: 200px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

.m-b-200 {
  margin-bottom: 200px !important;
}

/**/
.m-l-0 {
  margin-left: 0 !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

.m-l-200 {
  margin-left: 200px !important;
}

/**/
.m-r-0 {
  margin-right: 0 !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

.m-r-200 {
  margin-right: 200px !important;
}

.center-text {
  text-align: center !important;
}

.align-text-right {
  text-align: right !important;
}

.blur {
  filter: blur(5px);
}

.cursor {
  &-default {
    cursor: default !important;
  }
  &-pointer {
    cursor: pointer;
  }
  &-not-allowed {
    cursor: not-allowed;
  }
}

.inline-block {
  display: inline-block !important;
}

.danger {
  color: #ff005b !important;
}

.pull-right {
  float: right;
}

.default-padding {
  padding: 16px;
}

.full-width {
  width: 100%;
}

.subheading {
  margin-bottom: 16px;
}

.success {
  color: #27ae60 !important;
}

a {
  cursor: pointer;
}

.aux-color {
  color: #7b8d8d !important;
}

.aux-color-btn button {
  padding: 0 !important;
}

.aux-color-btn i {
  color: #7b8d8d !important;
}

.page-title {
  margin-top: 32px;
  color: #7b8d8d;
}

.page-content {
  background-color: #ffffff;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2) !important;
}

.m-t-1 {
  margin-top: 8px;
}

.m-t-2 {
  margin-top: 16px;
}

.m-t-3 {
  margin-top: 32px;
}

.m-t-4 {
  margin-top: 64px;
}

#homeFeatures nav a {
  font-weight: 600;
  color: #003271 !important;
  border: none !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

#homeFeatures nav a.active {
  border-bottom: solid 2px #f97b6b !important;
}

#homeFeatures nav a:first-child {
  margin-left: 8px;
}

#pageDetails {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

#pageDetails img {
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  border: solid 1px #d9dfea;
}

.tab-content-alt {
  background-color: #ebeef4;
  padding: 32px;
}

.btn-rank {
  background-color: #d9dfea;
  line-height: 24px;
  height: 24px;
  width: 24px;
  display: block;
  padding: 0 !important;
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -ms-border-radius: 24px !important;
  border-radius: 24px !important;
  color: #7b8d8d !important;
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-menu a {
  font-weight: 600;
}

#moreOptions img {
  height: 24px !important;
  width: 12px !important;
}

#moreOptions::after {
  content: none !important;
}

.form-container {
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(141, 151, 158, 0.2) !important;
}

.form-container label {
  color: #003271;
}

.save-preview {
  padding: 16px;
}

.subtitle-section small {
  color: #003271 !important;
  text-decoration: underline;
}

/*# sourceMappingURL=app.css.map */

button.navbar-toggler {
  border: none !important;
  outline: none !important;
}

#moreOptions {
  padding: 0 !important;
}

.view {
  cursor: pointer;
}

.h-1000 {
  height: 1000px !important;
}

ul {
  padding: 0 !important;
}

li {
  list-style-type: none !important;
}

ul.pagination {
  padding: 0 !important;
}

ul.pagination li div,
ul.pagination li i {
  margin-right: 8px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
}

ul.pagination li div.active {
  background-color: #f97b6b;
  color: #ffffff !important;
}

ul.pagination li i {
  line-height: 40px;
}
