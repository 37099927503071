.react-time-picker {
  display: inline-flex;
  position: relative;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--disabled {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid #ced4da;
    height: 31px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }
  $digit-width: 0.54em;
  $colon-width: 0.217em;
  &__inputGroup {

    // own padding + inputs padding + digits width + dots width
    min-width: calc((4px * 3) + #{$digit-width * 6 }  + #{$colon-width * 2});
    //min-width: calc((4px * 3) +  0.54em * 6  +  0.217em * 2);;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: $digit-width;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: transparentize(red, .1);
      }

      &--hasLeadingZero {
        margin-left: -$digit-width;
        padding-left: calc( 1px + #{$digit-width});
      }
    }

    &__amPm {
      font: inherit;
      -moz-appearance: menulist;
    }
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-time-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-time-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  &__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid rgb(160, 160, 150);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }
  }
}
