// stylelint-disable selector-no-qualifying-type
// Base styles

.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  padding: 12px;
  font-size: 13px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  color: $white !important;


  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &-primary {
    background-color: $primary-color ;

    &:hover {
      background-color: $primary-color-dark !important;
    }
  }

  &-secondary {
    background-color: $sn-deep-orange !important;


    &:hover {
      background-color: $sn-deep-orange-dark !important;
    }
  }


  &-danger {
    background: #ff005b !important;
    border: solid 1px #ff005b !important;
    font-weight: 600;

    &:hover {
      background-color: #e60052 !important;
      border-color: #e60052 !important;
    }
  }

  &-outline {
    background-color: transparent !important;
    color: #003271 !important;
    border: solid 1px #003271 !important;

    &:hover {
      background-color: #f1f7ff !important;
    }
  }

  &-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      background-color: transparent;
      border-color: transparent;
    }

    &:focus,
    &.focus {
      text-decoration: $link-hover-decoration;
      border-color: transparent;
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
      pointer-events: none;
    }

    // No need for an active state here
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    @include button-link-variant($value, $value);
  }
}


//
// Button Sizes
//


.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
