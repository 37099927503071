@import "includes/mixins";
@import "includes/variables";
.line-height-0 {
  line-height: 0;
}
.line-height-02 {
  line-height: 0.2;
}

.line-height-05 {
  line-height: 0.5;
}

.line-height-075 {
  line-height: 0.75;
}

.line-height-1 {
  line-height: 1;
}

.line-height-12 {
  line-height: 1.2;
}

.line-height-15 {
  line-height: 1.5;
}

.v-align- {
  &top {
    vertical-align: top;
  }
  &bottom {
    vertical-align: bottom;
  }
  &sub {
    vertical-align: sub;
  }
  &middle {
    vertical-align: middle;
  }
  &baseline {
    vertical-align: baseline;
  }
}


.MuiDrawer-paper.MuiPaper-root {
  @include mobile {
    width: 100% !important;
  }

}

div.MuiDrawer-root.MuiDrawer-modal {
  z-index: 1000 !important;
}

.hint-icon {
  width: 23px;
  display: inline-block;
  text-align: right;
  cursor: pointer;
  position: relative;

  //&::before, &::after {
  &::before {
    display: none;
    content: '';
    height: 0;
    width: 0;
    border: 10px solid;
    border-color: transparent;
    position: absolute;
    top: calc(100% + 2px);
    left: calc(100% - 10px);
    transform: translate(-50%, -50%);
    z-index: 30;
  }

  &::before {
    border-bottom: 10px solid;
    border-bottom-color: #FFFFFF !important;
    filter: drop-shadow(0 -4px 3px rgba(62, 97, 128, 0.2))
  }

  /*&::after {
    border-bottom: 10px solid;
    border-bottom-color: #FFFFFF !important;
  }*/

  p {
    color: #7b8d8d;
    font-size: 13px;
  }

  h6 {
    font-size: 13px;
  }

  &:hover {
    & > .validation-hint {
      display: block;
    }

    &::before, &::after {
      display: block;
    }
  }

  .validation-hint {
    display: none;
    //border: 1px solid $divider-color;
    box-shadow: 0 1px 8px rgba(62, 97, 128, 0.3);
    position: absolute;
    top: calc(100% + 10px);
    //left: 50%;
    //transform: translateX(-50%);
    padding: 10px;
    width: auto;
    min-width: 300px;
    border-radius: 6px;
    text-align: left;
    z-index: 22;
    background-color: #FFF;
    //background-image: $accent-gradient-light;
    //max-height: 0;
    right: -12px;
    overflow: auto;
    transition: all .2s ease-in-out;

    &.left {
      left: 0;
    }

    .heading {
      //font-size: .85em;
      font-family: $primary-font;
      font-weight: 600;
    }

    .format {
      display: block;
      font-weight: 600;
    }
  }

  &.right {
    text-align: left;

    .validation-hint {
      right: unset;
      left: -10px;
    }
  }
}

.MuiRating-root {
  color: #ff5722 !important;
  .MuiRating-label {
    .MuiRating-icon .MuiRating-iconFilled {
      svg.MuiSvgIcon-root {
        fill : #ff5722 !important
      }
    }

  }
}

