.l-grouping {
  background-color: rgba(206, 212, 218, 0.2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.emojis-container {
  width: 60%;
  margin: 0 auto;
}
.emoji-container {
  cursor: pointer;

  .emoji-text {
    color: transparent !important;
    display: none;
  }


  img {
    height: 53px;
    filter: grayscale(90%);
    transition: all 0.3s ease-in-out;
  }
  &.selected, &:hover {
     img {
      filter: grayscale(0%);
     }
  }

  &:hover {
    .emoji-text {
      color: rgba(108, 117, 125, 0.57) !important;
    }
  }
}
