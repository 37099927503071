:root {
  --text-color : #0078ff;
}
/*  typography */

/** Generated by FG **/
//@font-face {
//  font-family: 'NewYorkRegular';
//  src: url('/fonts/gi_regular.otf');
//  src: local('☺'), url('/fonts/gi_regular.otf') format('woff'), url('/fonts/gi_regular.otf') format('truetype'), url('/fonts/gi_regular.otf') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'Glacial';
  src: local('☺'), url('../../../fonts/gi_regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialBold';
  src: local('☺'), url('../../../fonts/gi_bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
  line-height: 1.2;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  //font-size: 15px;
  color: $text-color;
  //line-height: 1.7;
  //color: lighten($black, 50%);
  font-weight: 300;
  font-size: $font-size-base;
}

table {
  th, td {
    font-family: $primary-font !important;
  }
}

p, .paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 15px;
  line-height: 1.2;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $primary-font;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1{
  font-size: 60px;
  @include mobile {
    font-size: 45px;
  }
}

h2, .h2{
  font-size: 40px;
  @include mobile {
    font-size: 30px;
  }
}

h3, .h3{
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
}

h4, .h4{
  font-size: 20px;
  @include mobile {
    font-size: 18px;
  }
}

h5, .h5{
  font-size: 18px;
  @include mobile {
    font-size: 16px;
  }
}

h6, .h6{
  font-size: 16px;
  @include mobile {
    font-size: 14px;
  }
}

.text {
  &-muted {
    color: rgba(108, 117, 125, 0.57) !important;
  }

  &-smaller {
    font-size: .6em;
  }

  &-small {
    font-size: .75em;
  }

  &-small-normal {
    font-size: .8em;
  }

  &-normal, &-regular {
    font-size: 1em;
  }

  &-medium {
    font-size: 1.2em;
  }

  &-large {
    font-size: 1.5em;
  }

  &-larger {
    font-size: 1.8em;
  }
}

.text- {
  &sn-blue {
    color: $sn-blue !important;
  }

  &sn-blue-dark {
    color: $sn-blue-dark;
  }

  &secondary-font {
    font-family: "NewYork", serif;
  }
}
