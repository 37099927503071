.subscribe-mail {
  // Vars

  // Support

  // Module
  & {
    //
  }

  // Facets

  // States
}

.days-picker {
  // Vars

  // Support

  // Module
  & {
    table {
      tr td {
        cursor: pointer;
        div {
          width: 21px;
          height: 21px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          pointer-events: none;
          cursor: pointer;
        }

        &:hover > div {
            background: #e8e8e8;
        }

        &:active > div {
            background: #bcc3c8;
        }

        &.selected {
          div {
            background-color: #008cff;
            color: #fff;
          }
          &:hover {
            &>div{
              background-color: #0c4ff1;
            }
          }

          &:active {
            &>div {
              background-color: #0341d7;
            }
          }
        }
      }
    }
  }

  // Facets

  // States
}
