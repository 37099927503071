@mixin mobile-xs{
  @media(max-width:370px){
    @content;
  }
}
@mixin _mobile-xs{
  @media(min-width:370px){
    @content;
  }
}
@mixin mobile{
  @media(max-width:575px){
    @content;
  }
}
@mixin _mobile{
  @media(min-width:575px){
    @content;
  }
}
@mixin tablet{
  @media(max-width:768px){
    @content;
  }
}
@mixin _tablet{
  @media(min-width:768px){
    @content;
  }
}
@mixin kindle{
  @media(max-width:800px){
    @content;
  }
}
@mixin _kindle{
  @media(min-width:800px){
    @content;
  }
}
@mixin desktop{
  @media(max-width:991px){
    @content;
  }
}
@mixin _desktop{
  @media(min-width:991px){
    @content;
  }
}
@mixin desktop-lg{
  @media(max-width:1200px){
    @content;
  }
}
@mixin desktop-xl{
  @media(max-width:1400px){
    @content;
  }
}
@mixin short_screen{
  @media(max-height:508px){
    @content;
  }
}

@mixin size($size){
  width: $size; height: $size;
}
