.hero {
  background-image: url('../../images/hero.JPG');
  background-color: #fff;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  /* position: fixed; */

}




@media only screen and (max-width: 500px) {
  .loginContainer {
    display: flex;
  }

  .lg-form{
    width:100%
  }
  .hero {
    display: none;
  }
  .sp-1 {
    width:100%;
  }
}


@media only screen and (min-width: 500px){
  /* .hero {
    display: none;
  } */

  .loginContainer {
    width:100%;
    /* width: 100vw; */
    display: flex;
  }

  .lg-form {
    width:40%;
  }

}

/*
@media only screen and (min-width: 501px){


  .loginContainer {
    display: flex;
    width: 100%;
  }

  .lg-form {
    width:50%;

  }
} */
